import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Chambres from './pages/Chambres';
import Graphiques from './pages/Graphiques';
import AlarmesEvents from './pages/AlarmesEvents';
import Portes from './pages/Portes';
import Parametres from './pages/Parametres';
import NotFound from './pages/NotFound';
import { ProtectedRoutes } from './components/ProtectedRoutes';

function App() {
    // const auth = localStorage.getItem('isAuth');
    // const [isAuth, setIsAuth] = useState(false);
    // useEffect(() => {
    //     setIsAuth(localStorage.getItem('isAuth'));
    // }, [localStorage.getItem('isAuth')]);

    return (
        <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/chambres" element={
                <ProtectedRoutes>
                    <Chambres />
                </ProtectedRoutes>
            } />

            <Route path="/graphiques" element={
                <ProtectedRoutes>
                    <Graphiques />
                </ProtectedRoutes>
            } />

            <Route path="/alarmes&events" element={
                <ProtectedRoutes>
                    <AlarmesEvents />
                </ProtectedRoutes>
            } />

            <Route path="/portes" element={
                <ProtectedRoutes>
                    <Portes />
                </ProtectedRoutes>
            } />

            <Route path="/parametres" element={
                <ProtectedRoutes>
                    <Parametres />
                </ProtectedRoutes>
            } />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
// {/* <Routes>
//     <Route path="/" element={<Login />} />
//     <Route path="/chambres" element={isAuth ? <Chambres /> : <Navigate to="/" replace />} />
//     <Route path="/graphiques" element={isAuth ? <Graphiques /> : <Navigate to="/" replace />} />
//     <Route path="/alarmes&events" element={isAuth ? <AlarmesEvents /> : <Navigate to="/" replace />} />
//     <Route path="/portes" element={isAuth ? <Portes /> : <Navigate to="/" replace />} />
//     <Route path="/parametres" element={isAuth ? <Parametres /> : <Navigate to="/" replace />} />
//     <Route path="*" element={<NotFound />} />
// </Routes> */}